<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded">
        <div v-if="apiLoaded" class="wrap">
          <h1 v-if="config.id != null">
            <div>Редактирование<br> конфигурации id {{ config.id }}</div>
          </h1>
          <h1 v-else>
            <div>Создание конфигурации рейтинга
            </div>
          </h1>

          <div v-if="config.id != null" class="creation-date">Дата
            создания<span>{{ config.creationDateTime | dateFormat }}</span></div>

          <div>
            <v-row>
              <div>
                <v-select style="width:400px" :items="monitoringTypes" v-model="config.monitoringType"></v-select>
              </div>
            </v-row>
            <v-row>
              <div>
                <v-select style="width:400px"
                          :items="periods"
                          v-model="config.period"
                          @change="onPeriodChange($event)"
                          label="Период мониторинга"
                ></v-select>
              </div>
            </v-row>

            <v-row style="max-width:400px" v-if="config.monitoringType==='INTEGRAL'">
              <v-text-field style="width:400px" label="Название рейтинга" v-model="config.configParsed.title"></v-text-field>
            </v-row>

            <v-row v-if="config.monitoringType==='INTEGRAL'" style="margin-top: 50px; margin-bottom: 10px; display: block">
              <p><b>Компоненты интегрального рейтинга</b></p>
              <table v-if="config.configParsed.components && config.configParsed.components.length > 0">
                <tr v-for="(ratingComponent, componentIndex) in config.configParsed.components" :key="'_comp_'+componentIndex">
                  <td>
                    <v-autocomplete label="Рейтинг"
                              style="max-width: 600px"
                              :items="ratingComponents"
                              item-value="id"
                              item-text="title"
                              v-model="ratingComponent.rating"></v-autocomplete>
                  </td>
                  <td>
                    <v-text-field type="number" label="Вес" v-model="ratingComponent.weight"></v-text-field>
                  </td>
                  <td>
                    <v-icon @click="deleteComponent(componentIndex)" class="icon-item icon-trash-can"></v-icon>
                  </td>
                </tr>
              </table>
              <a href="#" @click.prevent="addRatingComponent()">Добавить компонент</a>
            </v-row>

            <v-row style="margin-top: 50px; margin-bottom: 10px">
              <div><p><b>Участвующие субъекты РФ</b></p>
                <div><a href="#" @click.prevent="selectAllRegions()">Выбрать все </a></div>
                <div><a href="#" @click.prevent="clearRegions"> Очистить </a></div>
              </div>
            </v-row>
            <v-row>
              <div>
                <v-checkbox v-for="region in regions" :key="region.code+'_chk'"
                            :label="region.name"
                            :input-value="config.configParsed?.regions?.includes(region.code)"
                            @change="setRegionParticipation(region.code, $event)"
                ></v-checkbox>
              </div>
            </v-row>

          </div>


          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/ratings2023')">Отмена</v-btn>
            <v-btn color="primary" @click="submit">Сохранить</v-btn>
          </div>
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

import {loadById, monitoringTypes, periods, saveConfig} from "@/modules/Rating2023Config";
import {getAvalableRatingComponents} from "@/modules/IntegralRating";

export default {
  name: "MonitoringConfig",
  props: {},
  components: {},
  data() {
    return {
      regions: [],
      ratingComponents: [],
      periods: periods,
      config: {
        monitoringType: 'ORG',
        period: 3,
        configParsed: {regions: []}
      },
      monitoringTypes: monitoringTypes,
      apiLoaded: false
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    addRatingComponent(){
      if(this.config.configParsed.components == null){
        this.config.configParsed.components = []
      }
      this.config.configParsed.components.push({
      })
      this.$forceUpdate()
    },

    deleteComponent(index){
      this.config.configParsed.components.splice(index, 1)
      this.$forceUpdate()
    },

    selectAllRegions() {
      this.config.configParsed.regions = this.regions.map(e => e.code)
      this.$forceUpdate()
    },

    clearRegions() {
      this.config.configParsed.regions = []
      this.$forceUpdate()
    },

    setRegionParticipation(regionCode, status) {
      if (status) {
        if (!this.config.configParsed.regions.includes(regionCode)) {
          this.config.configParsed.regions.push(regionCode)
        }
      } else {
        const index = this.config.configParsed.regions.indexOf(regionCode);
        if (index > -1) {
          this.config.configParsed.regions.splice(index, 1);
        }
      }
    },

    async onPeriodChange(period){
      this.ratingComponents = await getAvalableRatingComponents(period)
      this.$forceUpdate()
    },

    async submit() {
      this.config.configJson = JSON.stringify(this.config.configParsed)
      await saveConfig(this.config)
      await this.$router.push('/config/ratings2023')
    }
  },
  async beforeMount() {
    this.ratingComponents = await getAvalableRatingComponents(this.config.period)

    this.regions = this.$cznDepartmentsByRegionList.map(e => {
      return {
        code: e.code,
        name: e.name
      }
    })
    if (this.$route.params.id != null) {
      //режим редактирования
      this.config = await loadById(this.$route.params.id)
      this.config.configParsed = JSON.parse(this.config.configJson)
      console.log(this.config, this.config.configParsed?.regions?.includes('BRYANSK'))
      this.$forceUpdate()
      this.apiLoaded = true
    } else {
      this.apiLoaded = true
    }
  }
}
;
</script>
