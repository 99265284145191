import {get, httpDelete, post} from "@/modules/remote";

export async function loadAllExternalRatings() {
    return get(`/monitoring2023/integral/externalrating`)
}

export async function loadById(id) {
    let configs = await loadAllExternalRatings()
    return configs.find(e=>e.id==id)
}

export async function loadByPeriod(period){
    return (await loadAllExternalRatings()).filter(e=>e.period===period)
}

export async function save(externalRating) {
    return post(`/monitoring2023/integral/externalrating/`, externalRating, null)
}

export async function deleteConfigById(id){
    return httpDelete(`/monitoring2023/integral/externalrating/${id}`, null)
}
