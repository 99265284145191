import {
    getRating,
    loadConfigRating,
    setDepartmentsByRegion as setDepartmentsByRegionOrg,
    setPeriod as setPeriodOrg
} from "@/modules/OrgMonitor";
import {processMonitorRatings, setDepartmentsByRegion as setDepartmentsByRegionProcess} from "@/modules/ProcessMonitor";
import {effectivenessRatings, resultMonitorRatings, setDepartmentsByRegion as setDepartmentsByRegionResult} from "@/modules/new/ResultMonitorReports";
import {loadAllExternalRatings, loadByPeriod} from "@/modules/ExternalRating";

export async function getAvalableRatingComponents(period){
    let ratingComponents = []

    const orgMonitorRatingConfig = await loadConfigRating()
    ratingComponents = ratingComponents.concat(orgMonitorRatingConfig.sections.map(section => {
        return {
            id: 'ORG_' + section.id,
            title: section.name,
            legend: section.legend,
            dataFunction: getRating.bind(null, section.indicators[0]?.id)        }
    }))

    ratingComponents = ratingComponents.concat(processMonitorRatings.map(e=>{
        return {
            id: 'PROCESS_'+e.id,
            title: e.title,
            legend: e.legend,
            dataFunction: e.dataFunction
        }
    }))
    ratingComponents = ratingComponents.concat(resultMonitorRatings.map(e=>{
        return {
            id: 'RESULT_'+e.id,
            title: e.title,
            legend: e.legend,
            dataFunction: e.dataFunction
        }
    }))
    ratingComponents = ratingComponents.concat(effectivenessRatings.map(e=>{
        return {
            id: 'EFFECT_'+e.id,
            title: e.title,
            legend: e.legend,
            dataFunction: e.dataFunction
        }
    }))
    let externalRatings = []
    if(period) externalRatings = await loadByPeriod(period)
    else externalRatings = await loadAllExternalRatings()
    ratingComponents = ratingComponents.concat(
        externalRatings.map(e=>{
            e.id='EXT_'+e.id;
            e.title = e.name
            e.dataFunction = ()=>{return JSON.parse(e.ratingJson)}
            return e
        })
    )
    return ratingComponents
}

export async function createDataFunction(components){
    let ratingComponents = await getAvalableRatingComponents()
    let weights = []
    let ratingDataFunctions = []
    for(let component of components){
        let ratingComponent = ratingComponents.find(e=>e.id===component.rating)
        ratingDataFunctions.push(ratingComponent.dataFunction)
        weights.push(component.weight)
    }
    return async function(){
        let ratings = []
        for(let dataFunction of ratingDataFunctions){
            ratings.push(await dataFunction.call(this, ...arguments))
        }
        return combineRatings(ratings, weights)
    }
}

function combineRatings(ratings, weights){
    if(ratings.length !== weights.length) throw 'ratings.length !== weights.length'
    let resObj = {}
    let rating_i = 0
    for(let rating of ratings){
        for(let el of rating){
            let existingValue = resObj[el[0]] || 0
            resObj[el[0]] = existingValue + weights[rating_i]*el[1]
        }
        rating_i++
    }
    let resArr = []
    for(let key in resObj){
        resArr.push([key, resObj[key]])
    }
    return normalizeRating(resArr, 'П')
}

function combineRatingsForReport(ratings, weights){
    if(ratings.length !== weights.length) throw 'ratings.length !== weights.length'
    let resObj = {}
    let rating_i = 0
    for(let rating of ratings){
        for(let el of rating){
            let existingValue = resObj[el[0]]?.['integral'] || 0
            if(resObj[el[0]] == null) resObj[el[0]] = {}
            resObj[el[0]][rating_i] = el[1]
            resObj[el[0]]['integral'] = existingValue + weights[rating_i]*el[1]
        }
        rating_i++
    }
    return resObj
}

function normalizeRating(rating, type){
    let min = findMinValue(rating)
    let max = findMaxValue(rating)
    if(max === min) {
        max = 1
        min = 0
    }
    if(type==='П') {
        rating = rating.map(e => [e[0], (e[1] - min) / (max-min) * 100])
    } else if(type==='О'){
        rating = rating.map(e => [e[0], (max - e[1]) / (max-min) * 100])
    } else {
        console.error('Unknown rating normalization type ', type)
        return undefined
    }

    rating.sort((a,b)=>a[1]<=b[1]?1:-1)
    return rating
}

//value is in element[1] place
function findMinValue(arr){
    let curMin = 1e12
    for(let el of arr){
        if(el[1] < curMin){
            curMin = el[1]
        }
    }
    return curMin
}

//value is in element[1] place
function findMaxValue(arr){
    let curMax = -1e12
    for(let el of arr){
        if(el[1] > curMax){
            curMax = el[1]
        }
    }
    return curMax
}

export function setDepartmentsByRegion(departmentsByRegion){
    setDepartmentsByRegionOrg(departmentsByRegion)
    setDepartmentsByRegionProcess(departmentsByRegion)
    setDepartmentsByRegionResult(departmentsByRegion)
}

export function setPeriod(period) {
    setPeriodOrg(period)
}

export async function createReportDataFunction(config){
    return async function(){
        let ratingComponents = await getAvalableRatingComponents()
        let weights = []
        let ratingDataFunctions = []
        for(let component of config.components){
            let ratingComponent = ratingComponents.find(e=>e.id===component.rating)
            ratingDataFunctions.push(ratingComponent.dataFunction)
            weights.push(component.weight)
        }

        let ratings = []
        for(let dataFunction of ratingDataFunctions){
            ratings.push(await dataFunction.call(this, ...arguments))
        }
        let reportData = {
            headerCells: [
                {
                    caption: "Субъект РФ",
                    width: 5000,
                }
            ],
            rows: []
        }

        for(let component of config.components){
            reportData.headerCells.push({
                caption: ratingComponents.find(e=>e.id===component.rating).title,
                width: 5000
            })
        }
        reportData.headerCells.push({
            caption: 'Интегральный показатель',
            width: 5000
        })

        let reportObj = combineRatingsForReport(ratings, weights)
        for(let regionName in reportObj){
            let row = [{
                cellDataType: 'STRING',
                value: regionName
            }]
            for(let i = 0; i < config.components.length; i++){
                row.push({
                    cellDataType: 'DECIMAL',
                    value: reportObj[regionName][i]
                })
            }
            row.push({
                cellDataType: 'DECIMAL',
                value: reportObj[regionName]['integral']
            })
            reportData.rows.push(row)
        }

        return reportData
    }
}
